import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Blockquote from '../components/Blockquote';
import { NotFoundQuery } from '../../graphql-types';
import Checklists from '../components/Checklists';
import SEO from '../containers/SEO';

import './o-not-found-section.scss';
import './o-suggested-checklists.scss';

interface Props {
  data: NotFoundQuery;
}

const NotFoundPage: FC<Props> = ({ data }) => (
  <Layout>
    <SEO title="Ooops..." />
    <div className="row o-not-found-section">
      <div className="col col--md-10 col--lg-8 u-text-align-center">
        <div className="u-padding-bottom-large">
          <svg
            className="u-img-fluid"
            width="712"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 676 464"
          >
            <title>Content not found</title>
            <g fill="none" fillRule="evenodd">
              <g fillRule="nonzero">
                <path
                  fill="#232939"
                  d="M212.437 309.291v-44.797l29.524 4.088 4.758-4.088v-38.03h-34.282V100.13h-54.468l-4.763 4.088L70.5 218.085v46.409h98.362v44.797l38.792 4.088 4.783-4.088zm-42.601-82.438h-59.874l59.874-79.713v79.713zm182.342 87.889c31.173 0 54.31-13.187 68.45-35.057 12.534-18.975 18.64-43.74 18.64-74.293 0-30.554-6.106-55.319-18.64-74.294-14.14-21.87-37.277-35.056-68.45-35.056-31.172 0-54.31 13.186-68.77 35.056-12.213 18.654-18.319 43.418-18.319 74.294 0 30.875 6.106 55.64 18.318 74.293 14.461 21.87 37.6 35.057 68.771 35.057zm0-39.516c-13.858 0-24.493-5.794-31.584-17.061-7.412-11.91-11.28-29.615-11.28-53.114s3.868-41.204 11.28-53.114c7.09-11.267 17.726-17.06 31.584-17.06 13.859 0 24.172 5.793 31.262 17.06 7.735 11.589 11.603 29.293 11.603 53.114 0 23.82-3.868 41.525-11.603 53.114-7.09 11.267-17.403 17.06-31.262 17.06zm252.84 34.065v-44.797l29.594 4.088 4.688-4.088v-38.03h-34.283V100.13H550.55l-4.763 4.088-82.706 113.868v46.409h98.362v44.797l38.876 4.088 4.698-4.088zm-43.282-82.438h-59.873l59.873-79.713v79.713z"
                />
                <path
                  fill="#181C27"
                  d="M207.937 312.891v-44.797h34.282v-38.03h-34.282V103.73h-54.468L66 221.685v46.409h98.362v44.797h43.575zm-42.601-82.438h-59.874l59.874-79.713v79.713zm182.342 87.889c31.173 0 54.31-13.187 68.45-35.057 12.534-18.975 18.64-43.74 18.64-74.293 0-30.554-6.106-55.319-18.64-74.294-14.14-21.87-37.277-35.056-68.45-35.056-31.172 0-54.31 13.186-68.77 35.056-12.213 18.654-18.319 43.418-18.319 74.294 0 30.875 6.106 55.64 18.318 74.293 14.461 21.87 37.6 35.057 68.771 35.057zm0-39.516c-13.858 0-24.493-5.794-31.584-17.061-7.412-11.91-11.28-29.615-11.28-53.114s3.868-41.204 11.28-53.114c7.09-11.267 17.726-17.06 31.584-17.06 13.859 0 24.172 5.793 31.262 17.06 7.735 11.589 11.603 29.293 11.603 53.114 0 23.82-3.868 41.525-11.603 53.114-7.09 11.267-17.403 17.06-31.262 17.06zm252.84 34.065v-44.797H634.8v-38.03h-34.283V103.73H546.05l-87.47 117.956v46.409h98.363v44.797h43.574zm-43.282-82.438h-59.873l59.873-79.713v79.713z"
                />
              </g>
              <path
                fill="#7192D5"
                d="M547.315 114.385S533 106.333 518.923 93.788c-18.46-16.45-33.962-41.286-33.962-41.286s11.902-16.798 18.133-9.097c6.94 10.204 20.743 23.246 33.225 33.18 7.785 6.197 31.642 18.73 31.642 18.73"
              />
              <path
                fill="#4E679C"
                d="M571.364 89.606c-9.912 2.004-20.367 2.004-38.9-11.721L518.96 95.989s22.5 16.982 36.084 22.395l16.32-28.778z"
              />
              <path
                fill="#344976"
                fillRule="nonzero"
                d="M533.503 100.388l23.061 7.743 4.916-6.796c-8.202.512-15.038-2.782-20.51-9.883-5.472-7.1-7.961-4.122-7.467 8.936z"
              />
              <path
                fill="#92AEE7"
                d="M135.625 266.194s26.516 4.103 44.398.849c23.45-4.267 46.463-26.272 46.463-26.272s1.274-9.978-7.736-6.781c-10.866 4.895-30.189 11.242-45.436 13.154-9.51 1.194-31.762 3.665-31.762 3.665l-5.927 15.385z"
              />
              <path
                fill="#344976"
                d="M136.576 244.655s5.425 1.068 14.35 1.068c8.923 0 21.88 1.082 21.88 1.082l3.042 25.59-26.042 3.99c-10.92 2.317-18.186-.07-21.799-7.16-3.613-7.09-.756-15.28 8.569-24.57z"
              />
              <g fillRule="nonzero">
                <path
                  fill="#D00046"
                  d="M437.938 236.85l20.963 5.35 16.617 17.99 23.71 5.122 7.644 7.943-35.317 2.287-25.054-22.76z"
                />
                <path
                  fill="#FE2D5B"
                  d="M437.937 236.85l8.564 15.933h31.308l13.4 18.968 15.663 1.504-137.31 160.804c-13.441 15.137-36.103 17.68-52.549 5.988l-.496-.359-87.92-59.489c-8.006-5.417-10.304-16.192-5.203-24.405l23.033-37.083c5.245-8.445 16.343-11.04 24.788-5.794a18 18 0 01.409.262l38.499 25.377c13.862 9.138 32.338 6.668 43.313-5.79l84.501-95.915z"
                />
                <path
                  fill="#C30041"
                  d="M253.453 312.913c.462-.456 1.87-1.248 4.224-2.378 2.354-1.129 6.3-2.204 11.837-3.225l63.579 33.056L439.51 237.7l3.025 5.067-84.196 97.883c-14.29 16.613-38.902 19.562-56.712 6.796l-48.174-34.533z"
                />
                <path
                  fill="#AD003A"
                  d="M242.74 324.614c7.88-7.534 15.505-8.565 22.876-3.093 7.527 5.587 8.325 5.935 14.432 10.423 8.424 6.192 15.677 11.045 22.575 15.604 13.04 8.616 30.237 5.106 51.591-10.53L274.508 303.4c2.812 2.122-.56 3.701-10.119 4.739-9.557 1.037-16.774 6.53-21.648 16.475zM491.209 271.75l15.663 1.505-7.644-7.943-14.923-3.223z"
                />
                <path
                  fill="#C30041"
                  d="M315.693 439.295l10.714 5.444 12.914-7.883 13.569 2.7 14.086-2.7 38.655-45.14-43.378 41.989H339.32l-12.914 7.697z"
                />
              </g>
              <path
                fill="#5D7AB6"
                fillRule="nonzero"
                d="M176.19 268.613c11.112-3.17 18.716-5.901 22.814-8.195l4.246-9.574c-7.926 9.01-17.823 11.906-29.69 8.686l2.63 9.083z"
              />
              <g fillRule="nonzero">
                <path
                  fill="#D00046"
                  d="M418.989 101.914l5.383 16.05 15.332 2.08 3.284.4 1.233 7.78 5.702 2.944 5.393 11.417 17.794 5.286 28.815-.16 2.486-8.886-70.902-35.558z"
                />
                <path
                  fill="#FE2D5B"
                  d="M558.702 6.276l31.346 33.443c6.778 7.23 6.435 18.58-.766 25.389l-87.358 82.602-28.813.16-8.605-17.915-23.75-7.383-7.247-19.304-14.52-1.354L523.002 8.868a18 18 0 016.054-3.574l10.566-3.698a18 18 0 0119.08 4.68z"
                />
                <path
                  fill="#E6004D"
                  d="M418.989 101.914l14.52 1.354L547.482.634c-2.052-.21-4.245-.003-6.58.623-2.015.54-6.18 2-12.492 4.382a18 18 0 00-5.65 3.427L418.99 101.914z"
                />
                <path
                  fill="#AD003A"
                  d="M443.377 123.444l.844 4.78 5.702 2.944 5.393 11.417 17.794 5.286-8.604-17.916z"
                />
              </g>
              <g fillRule="nonzero">
                <path
                  fill="#FE2D5B"
                  d="M476.217 376.3h164.378c9.94 0 18 8.059 18 18v42.3c0 9.941-8.06 18-18 18h-150.63l-9.784-12.527 7.784-18.99-17.156-23.736 5.408-23.047z"
                />
                <path fill="#D00046" d="M493.19 373.3l-17.137 3 146.141 2.613 21.341-2.613z" />
                <path
                  fill="#E6004D"
                  d="M646.117 454.6h-12.285c8.632-3.038 14.26-10.236 16.882-21.593 2.623-11.357 5.25-23.818 7.88-37.38v46.496c0 6.89-5.586 12.477-12.477 12.477z"
                />
              </g>
              <g fillRule="nonzero">
                <path
                  fill="#181C27"
                  d="M4.027 449.852l34.346-.024 60.141-.045 197.007-.149 19.703-10.148 11.183 5.253 12.914-7.884 13.569 2.7 14.876-2.7 13.5 11.808h101.848l4.85 5.937h159.281c1.788-.385 3.235-.882 4.34-1.49.796-.437 7.976-.56 21.54-.37a2.74 2.74 0 01.69 5.382l-17.664 4.868a4 4 0 01-1.063.144H24.626a4 4 0 01-1.205-.186l-20.416-6.447a3.403 3.403 0 011.022-6.65z"
                />
                <path
                  fill="#232939"
                  d="M308.552 443.06l18.11 6.107 16.749-4.425 15.566 6.417 7.999-14.304-14.086 2.7-13.569-2.7-12.914 7.883-10.714-5.444z"
                />
              </g>
              <g fillRule="nonzero">
                <path
                  fill="#C30041"
                  d="M408.51 169.3h11.938l14.513 18.261 28.896-1.79 16.63 4.383-45.526 5.4-26.451-16.972z"
                />
                <path
                  fill="#FE2D5B"
                  d="M405.692 158.608l10.573 19.44 16.81 1.33 15.167 14.112 32.244-3.336-122.361 140.983c-13.388 15.385-36.24 18.065-52.808 6.29l-.5-.362-101.603-71.444c-7.716-5.426-9.935-15.886-5.087-23.977l24.819-41.416a18 18 0 0116.931-8.686l10.037.835 62 37.456a12 12 0 0015.108-2.225l60.24-66.652 18.43-2.348z"
                />
                <path
                  fill="#E6004D"
                  d="M223.115 199.771c2.719-3.246 5.262-5.43 7.63-6.552 1.632-.773 5.073-1.936 10.321-3.49a18 18 0 0115.113 2.296l55.596 37.169a12 12 0 0015.564-1.921l38.338-42.332 18.43-2.348-54.21 63.335a12 12 0 01-16.087 1.965l-68.051-48.565a18.977 18.977 0 00-22.644.443z"
                />
                <path
                  fill="#C30041"
                  d="M319.749 231.212l1.853 14.226a4 4 0 006.988 2.104l77.102-88.934-19.73 2.21-59.74 67.64c-1.583.984-2.74 1.631-3.47 1.942-.73.31-1.73.581-3.003.812z"
                />
              </g>
              <path
                fill="#92AEE7"
                d="M530 341.35l1.59 12.464v21.237h-36.238a2.172 2.172 0 01-.76-4.202l23.274-9.741 1.912-20 10.222.243zM635.399 329.323l8.814 12.086 9.413 16.304-31.383 18.119a2.172 2.172 0 01-2.759-3.26l15.285-20.072-8.343-18.276 8.973-4.901z"
              />
              <path
                fill="#344976"
                d="M557.41 179.26s3.525 73.96 15.81 94.53c11.436 19.153 57.134 75.037 57.134 75.037l14.5-6.476s-45.091-83.497-47.555-90.326c-2.463-6.83 28.89-66.943 28.89-66.943s-23.62-8.49-35.372-25.042l-33.406 19.22z"
              />
              <path
                fill="#2A3C65"
                fillRule="nonzero"
                d="M599.013 243.055l5.623-13.692-37.23 29.447c15.467-4.181 26.003-9.433 31.607-15.755z"
              />
              <path
                fill="#4E679C"
                d="M581.876 186.032s-41.792 45.413-51.15 67.487c-10.472 24.695-14.04 98.513-14.04 98.513l15.01 2.767s20.422-80.344 27.287-89.713c4.294-5.862 69.516-38.095 67.206-81.103l-44.313 2.049z"
              />
              <path
                fill="#5D7AB6"
                d="M572.078 89.034s-15.71 5.267-17.433 28.593c-1.871 10.821 3.227 71.228 3.228 71.18 33.356 5.76 68.316-4.824 68.316-4.824s-7.87-46.836-10.24-60.995c-5.953-35.546-28.905-33.954-28.905-33.954h-14.966z"
              />
              <path
                fill="#92AEE7"
                d="M561.87 80.261c3.276 3.6 12.947 4.23 17.062.66 1.186-1.03 8.586-3.923 9.763-7.5 1.855-5.636-2.856-12.663-3.916-14.42-6.617-10.977-22.13-6.996-22.13-6.996s-2.605 7.84-3.25 15.557c-.081.976-.131 1.95-.141 2.906-.04 3.952.613 7.596 2.611 9.793z"
              />
              <path
                fill="#92AEE7"
                d="M584.528 73.301l3.212 15.733s-10.743 10.766-16.376.572l-.175-11.06 13.34-5.245zM132.434 228.11l-6.234 18.332s8.314 11.132 17.37 3.27l1.284-14.135-12.42-7.467z"
              />
              <path
                fill="#92AEE7"
                d="M153.795 235.921c-3.465 3.197-8.14 4.062-11.88.347-1.102-1.095-8.75-4.322-10.947-7.91-3.336-5.447-1.149-11.8 0-13.378 7.28-9.994 22.03-6.717 22.03-6.717s7.464 21.507.797 27.658z"
              />
              <path
                fill="#5D7AB6"
                fillRule="nonzero"
                d="M137.202 233.545c2.487 2.287 4.96 3.841 7.417 4.663l-.432 4.468c-2.14-1.55-3.608-2.843-4.4-3.88-.794-1.037-1.655-2.787-2.585-5.251z"
              />
              <path
                fill="#5D7AB6"
                d="M143.782 247.886s13.34 8.974 13.363 32.77c.01 10.585-13.482 52.684-13.476 52.64-16.144 20.312-65.749-3.617-65.749-3.617s10.955-32.114 15.341-45.237c10.118-30.277 33.343-38.867 33.343-38.867l17.178 2.311z"
              />
              <path
                fill="#344976"
                fillRule="nonzero"
                d="M128.604 263.607c-.697 12.731 8.41 21.341 27.323 25.83l1.284-6.733-28.607-19.097z"
              />
              <path
                fill="#92AEE7"
                d="M48.119 415.386l-15.93-4.616H14.42v36.804c.005 1.22.984 2.203 2.181 2.196.9-.004 1.702-.57 2.02-1.424l10.742-24.637 18.757-1.687v-6.636zM166.78 420.242L165.603 436l-.414 17.942h36.238a2.172 2.172 0 00.76-4.202l-24.273-9.741L177 420l-10.221.242z"
              />
              <path
                fill="#344976"
                d="M125.389 333.244c.553 1.135 15.521 84.772 15.564 85.073 2.976 20.827-5.084 33.89-35.85 31.374-42.972-3.514-72.915-24.45-72.915-24.45l.001-14.47c14.699.628 28.362 2.038 40.989 4.229 12.627 2.191 26.316 5.411 41.067 9.66l-27.387-57.292S69.495 349.188 78.795 330l46.594 3.244z"
              />
              <path
                fill="#2A3C65"
                fillRule="nonzero"
                d="M87.366 368.124c11.966 12.286 27.737 17.369 47.312 15.248l-2.264-11.786-45.048-3.462z"
              />
              <path
                fill="#344976"
                fillRule="nonzero"
                d="M77.92 329.679c21.037-.189 42.953 1.016 65.75 3.616-31.21-8.304-52.41-11.608-63.603-9.912l-2.147 6.296zM554.232 132.468c9.258.514 15.33.775 18.218.783 1.52.003 2.827-.023 3.918-.081 8.718-.461 15.374-2.008 19.97-4.64 11.878-6.803 12.783-25.071.342-26.397l-42.448 23.79v6.545z"
              />
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M92.313 323l-12.246.383c6.822-25.444 13.487-43.484 19.996-54.12 6.508-10.637 15.355-18.533 26.54-23.688-22.86 17.258-34.29 43.067-34.29 77.424z"
              />
              <path
                fill="#4E679C"
                d="M202.636 346.094c4.198 6.344 1.707 16.901-1.783 29.038C197.856 385.547 179.26 436 179.26 436h-13.657c-.514-20.763.343-36.348 2.573-46.757 2.23-10.409 5.25-17.706 9.061-21.892l-44.029 7.78s-73.648 8.66-55.288-46.163c4.09-1.696 13.013-1.896 26.77-.599 18.726 1.766 92.828 9.987 97.946 17.725z"
              />
              <path
                fill="#344976"
                fillRule="nonzero"
                d="M177.237 367.351l5.98-1.726c-9.732 8.366-15.603 31.824-17.614 70.375-.266-22.425.41-37.759 2.03-46 2.099-10.68 5.3-18.23 9.604-22.649z"
              />
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M130.357 217.373c2.824.13 4.236.875 4.236 2.234 0 2.038 3.115.967 3.742-2.234.627-3.201 6.174-7.78 7.938-7.518 1.765.262 4.195 2.695 7.766 1.753l-1.04-3.345-12.942-3.582-9.7 12.692z"
              />
              <path
                fill="#5D7AB6"
                d="M115.617 231.992c-2.071-3.541-3.208-10.584-1.812-17.234 1.015-4.83 7.799-7.754 10.455-9.898.473-.38.99-.71 1.409-1.15.94-.992 1.267-2.407 1.81-3.667.447-1.033 1.098-2.028 2.074-2.559 2.243-1.215 5.225.434 7.466-.783.485-.266.895-.647 1.333-.984 3.212-2.476 6.857-1.899 10.36-.611 1.638.604 2.177 1.262 3.54 2.345 1.49 1.18 3.552 1.101 5.05 2.258 1.572 1.213 1.968 3.44 1.984 5.452.007.728-.026 1.479-.33 2.14-.387.848-1.177 1.44-2.015 1.828-2.007.932-4.264.572-6.393.41-2.08-.162-4.497-1.654-6.53-.878-1.984.753-8.242 7.082-8.863 7.98-1.234 1.785-8.115-1.48-4.162 8.022.643 1.545 4.313 2.701 3.583 4.419-.73 1.717-.203 10.524-5.29 11.58-5.086 1.054-13.302-8.042-13.67-8.67z"
              />
              <path
                fill="#5D7AB6"
                fillRule="nonzero"
                d="M571.19 83.301c2.169-.506 4.927-2.19 8.275-5.055-1.65 4.822-4.365 7.795-8.147 8.92l-.129-3.865z"
              />
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M562.027 53.909l-.575 2.297c2.52.296 4.841-.145 6.963-1.32 3.183-1.765 7.987 3.254 8.322 5.043.334 1.789 2.065 6.73 3.197 4.899.394-.639.985-2.037 1.783-2.455.485-.254 1.383-.254 2.692 0L570.987 49.81l-8.96 4.1z"
              />
              <path
                fill="#5D7AB6"
                d="M600.86 76.992c2.07-3.541 3.208-10.584 1.81-17.234-1.014-4.83-7.798-7.754-10.454-9.898-.473-.38-.99-.71-1.409-1.15-.94-.992-1.267-2.407-1.81-3.667-.447-1.033-1.098-2.028-2.074-2.559-2.242-1.215-5.225.434-7.466-.783-.485-.266-.895-.647-1.333-.984-3.212-2.476-6.857-1.899-10.36-.611-1.638.604-2.177 1.262-3.54 2.345-1.49 1.18-3.552 1.101-5.05 2.258-1.572 1.213-1.968 3.44-1.984 5.452-.007.728.026 1.479.33 2.14.387.848 1.178 1.44 2.015 1.828 2.007.932 4.264.572 6.393.41 2.08-.162 4.497-1.654 6.53-.878 1.985.753 8.242 7.082 8.863 7.98 1.234 1.785 6.419-1.883 2.929 8.204-.548 1.583-3.014 1.937-2.284 3.655.481 1.13.137 11.106 5.223 12.161 5.087 1.055 13.303-8.04 13.67-8.669z"
              />
              <path
                fill="#92AEE7"
                fillRule="nonzero"
                d="M626.041 183.968c-19.943 6.684-42.666 8.298-68.168 4.84 30.55 1.144 52.772-3.513 66.669-13.97l1.5 9.13z"
              />
              <path
                fill="#E6004D"
                fillRule="nonzero"
                d="M198.439 260.756c20.492 1.617 32.058 1.948 34.698.994 3.96-1.432-3.193-10.652 3.417-10.074 6.61.578 5.567 4.338 8.713 3.67 3.146-.667 5.273-4.184 7.865-1.346 1.727 1.892-.431 5.695-6.476 11.41l-29.832 6.91-10.567-4.546c-2.308-1.436-3.915-2.56-4.821-3.373-.906-.813-1.905-2.028-2.997-3.645z"
              />
              <path
                fill="#92AEE7"
                fillRule="nonzero"
                d="M235.114 268.291c1.648-4.562 2.113-8.21 1.397-10.943-1.074-4.098 1.242-4.177 3.238-2.6 1.996 1.578 4.999 5.405 10.79 1.043 5.791-4.362 8.401-.795 8.831 2.58.43 3.374-2.807 4.032-2.67 5.475.136 1.443-7.069 9.685-16.344 8.376l-5.242-3.931z"
              />
              <path
                fill="#92AEE7"
                d="M147.19 275.256s24.735 10.396 42.873 11.564c23.785 1.534 52.075-14.5 52.075-14.5s3.013-9.124-6.503-8.202c-11.727 2.122-32.01 3.605-47.268 1.772-9.517-1.143-29.77-11.89-29.77-11.89l-11.408 21.256z"
              />
              <path
                fill="#4E679C"
                d="M140.19 247.422s12.594 1.452 20.69 5.19c8.094 3.739 20.723 9.224 20.723 9.224l-9.36 25.072-27.466-7.025c-10.782-2.893-16.173-8.318-16.173-16.276s3.862-13.353 11.585-16.185z"
              />
              <path
                fill="#E6004D"
                fillRule="nonzero"
                d="M490.733 78.884c-1.737 2.959-1.91 6.005-.517 9.14 2.089 4.7 11.283 8.093 12.473 8.093 1.19 0 6.287 18.599 22.844 29.362l8.593-8.176-29.362-29.28-14.031-9.139z"
              />
              <g fill="#92AEE7">
                <path
                  fillRule="nonzero"
                  d="M514.653 90.443c-1.243-4.69-1.389-8.363-.437-11.023 1.427-3.99-.874-4.27-3-2.872-2.126 1.398-5.45 4.948-10.84.098-5.388-4.85-8.299-1.524-9.021 1.8-.723 3.324 2.445 4.261 2.183 5.687-.262 1.426 6.197 10.264 15.551 9.769l5.564-3.46z"
                />
                <path d="M590.635 126.777s-27.328 2.669-45.635-1.934c-24.006-6.035-38.736-31.204-38.736-31.204s-.315-7.015 8.806-3.064c10.931 5.848 23.525 11.784 39.192 14.806 9.774 1.886 36.373-4.85 36.373-4.85v26.246z" />
                <path
                  fillRule="nonzero"
                  d="M590.443 126.785c7.18 0 13.254-6.294 13.254-13.474 0-7.18-5.154-14.004-13.062-12.78-7.909 1.224-13.446 6.544-13.446 13.724 0 7.18 6.074 12.53 13.254 12.53z"
                />
              </g>
              <path
                fill="#4E679C"
                fillRule="nonzero"
                d="M573.403 103.757c4.482 5.938 5.744 14.693 3.786 26.266 21.268-1.38 30.601-9.735 28-25.065-1.64-4.575-5.19-6.862-10.649-6.862-3.2 0-10.246 1.887-21.137 5.661z"
              />
            </g>
          </svg>
        </div>

        <h1>Ooops...</h1>
        <p className="u-text-style-large-body">The page you are looking for is lost.</p>
      </div>
    </div>
    <hr className="u-margin-ends-0 u-color-primary-900" />
    <section className="o-suggested-checklists">
      <h2 className="u-margin-bottom">Have you seen these checklists?</h2>
      <div className="row">
        <div className="col col--md-9 col--lg-6">
          <Blockquote>
            We have a checklist for both creating an illustration and for 404 pages. You should go
            ahead and check ‘em out!{' '}
            <span role="img" aria-labelledby="unicorn">
              🦄
            </span>
          </Blockquote>
        </div>
      </div>
      <Checklists items={data.suggestions.nodes} className="u-margin-top-medium" />
    </section>
  </Layout>
);

export const pageQuery = graphql`
  query NotFound {
    suggestions: allMarkdownRemark(
      filter: { fields: { slug: { in: ["designing-a-404-page", "creating-ui-illustrations"] } } }
    ) {
      nodes {
        ...Checklist
      }
    }
  }
`;

export default NotFoundPage;
